//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


  export default {
	name: "Footer",
	data() {
	  return {
		html: "",
		whatsapp: "",
		facebook: "",
		email: "",
		bootbakcolor: "",
		opentime: [],
		webset: [],
		id: "",
		uniacid: parseInt(window.localStorage.getItem("uniacid")),
		tabBarList3: [],
		iframeVisible: false,
		iframeUrl: "",
		tel: "loading",
		qyname: "",
		address: "loading",
		locale: this.$i18n.locale,
	  };
	},
	created() {
	  this.getAutoLink();
	},
	computed: {
		hasLinks() {
    return this.whatsapp || this.facebook || this.uniacid === 15 || this.uniacid === 17 || this.uniacid === 19 || this.uniacid === 20;
  }
},
    
	methods: {
	  openPopup(index) {
		if (this.uniacid == 26) {
			console.log("openPopup called with uniacid:", this.uniacid, "and index:", index);
		  // Trendy
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/qkx7u_PTkkn4j263-OB2F";
		  this.iframeVisible = true;
		} else if (this.uniacid == 22) {
		  // Ho Chun
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/N9JAGmog3UP0ZEvaNasJT";
		  this.iframeVisible = true;
		} else if (this.uniacid == 15) {
		  // Alex Au
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/xM-hfhOrUvRfOMQ19kCsJ";
		  this.iframeVisible = true;
		} 
		else if (this.uniacid == 43) {
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/g8iskMpG-G_M9JdhNrcV7";
		  this.iframeVisible = true;
		} else if (this.uniacid == 24 && index === 0) {
		  // SK Easy and first item in tabBarList3
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/otAB3VzMlW2EmMlr3lNOp";
		  this.iframeVisible = true;
		}
		else if (this.uniacid == 17) {
		
		  this.iframeUrl = "https://www.chatbase.co/chatbot-iframe/zPA1BOaPF3XHAo-JpQrAg";
		  this.iframeVisible = true;
		}
	  },
	  closePopup() {
		this.iframeVisible = false;
		this.iframeUrl = "";
	  },
	  async getAutoLink() {
		var ss = window.localStorage.getItem("webinfo");
		var ssarray = JSON.parse(ss);
		this.webset = ssarray;
		this.uniacid = ssarray['ucid'];
		this.address = ssarray["address"];
		this.email = ssarray["email"];
		this.tel = ssarray["tel"];
		this.qyname = ssarray["qyname"];
		this.whatsapp = ssarray["whatsapp"];
		this.facebook = ssarray["facebook"];
		this.bootbakcolor = ssarray["bootbakcolor"];
		this.opentime = ssarray["opentime"].split(",");
		const res = await this.$http.get("/user/botmenu", {
		  uniacid: this.uniacid,
		});
		this.tabBarList3 = res.data;
		console.log("tabBarList3:", this.tabBarList3);
	  },
	  logItem(item, index) {
		console.log("Item:", item, "Index:", index);
	  },
	},
  };

  