import axios from "axios";

axios.defaults.timeout = 8000
axios.defaults.withCredentials = true
axios.defaults.baseURL = '/v1'

axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem('login@token') || ''
    config.headers.Authorization = token
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    // config.headers.Cookie = token ? `PHPSESSID=${token}` : ''
    return config

}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(function(response){
  let res=response.data
  // if(res.code == 401){
  //   window.location.href='/#/login'
  //   return Promise.reject(res);
  // }
  return Promise.resolve(res);
}, function (error) {
  // 对响应错误做点什么
  console.log(error)
  if (error.response.status === 401) {
    window.location.href='/'
  }
  return Promise.reject(error);
})

const http = {
    get: async(url,params,config) => {
        return await new Promise((resolve, reject) => {
			axios.get(url, {params}).then((res) => {
				setTimeout(() => {
					resolve(res)
				}, 1)
			})
		})
    },
    post: async(url,data,config) => {
        return await new Promise((resolve, reject) => {
			axios.post(url, data).then((res) => {
				setTimeout(() => {
					resolve(res)
				}, 1)
			})
		})
    },
}

export default http