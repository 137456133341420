import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import NotFound from '@/views/NotFound.vue'
import BrandFilter from '@/views/user/components/brand-filter/BrandFilter.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
				meta:{title:'首页'},
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
            },
            {
                path: '/cart',
                name: 'Cart',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Cart" */ '../views/cart/index.vue')
            },
            {
                path: '/product',
                name: 'Product',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Product" */ '../views/product/index.vue')
            },
            {
                path: '/newslist',
                name: 'newslist',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Product" */ '../views/newslist/index.vue')
            },
            {
                path: '/detail',
                name: 'newsdetail',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Product" */ '../views/newslist/detail.vue')
            },
            {
                path: '/user',
                name: 'User',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "User" */ '../views/user/index')
            },
            {
                path: '/orderDteail',
                name: 'OrderDteail',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "OrderDteail" */ '../views/orderDteail/index')
            },
            {
                path: '/orderConfirm',
                name: 'orderConfirm',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "OrderDteail" */ '../views/order_confirm/index')
            },
            {
                path: '/productList',
                name: 'ProductList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "ProductList" */ '../views/productList/index')
            },
            {
                path: '/newmannterms',
                name: 'newmannterms',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "ProductList" */ '../views/newmannterms.vue')
            },

            {
                path: '/ufaboutus',
                name: 'ufaboutus',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "ProductList" */ '../views/user/components/ufAboutus.vue')
            },

            {
                path: '/ufterms',
                name: 'ufterms',
                component: () => import('../views/ufterms.vue')
            },
            {
                path: '/ufterms-en',
                name: 'ufterms-en',
                component: () => import('../views/uftermsEn.vue')
            },
            {
                path: '/brand-lp',
                component: BrandFilter
            },
            {
                path: '/brand-uf',
                component: BrandFilter
            },
            {
                path: '/brand-allstar',
                component: BrandFilter
            },
            {
                path: '/brand-uhlmann',
                component: BrandFilter
            },
            {
                path: '/brand-abc',
                name: 'BrandABC',
                component: BrandFilter,
                meta: { uniacid: 43 }
            },
            {
                path: '/brand-def',
                name: 'BrandDEF',
                component: BrandFilter,
                meta: { uniacid: 43 }
            },
            {
                path: '/brand-ghi',
                name: 'BrandGHI',
                component: BrandFilter,
                meta: { uniacid: 43 }
            },
            {
                path: '/brand-jkl',
                name: 'BrandJKL',
                component: BrandFilter,
                meta: { uniacid: 43 }
            },
            {
                path: '/service-provider',
                name: 'ServiceProvider',
                component: () => import(/* webpackChunkName: "ProductList" */ '../views/user/components/k520c-provider.vue')
            },

             
           
            {
                path: '/aboutus-k520c',
                name: 'aboutus-k520c',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "ProductList" */ '../views/user/components/Aboutus-K520c.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Home" */ '../views/login')
    }
    ,
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
    // Handle uniacid switching
    if (to.meta.uniacid) {
        window.localStorage.setItem('uniacid', to.meta.uniacid);
    }

    // Your existing navigation guard logic
    if (to.name === 'aboutus' && i18n.locale === 'EN') {
        window.location.href = '/#/ufaboutus-en';
    } else if (to.name === 'aboutus-en' && i18n.locale !== 'EN') {
        window.location.href = '/#/ufaboutus';
    } else {
        next();
    }   
})
export default router
